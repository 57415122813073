import React, { useRef } from 'react';
import { bool, shape, string } from 'prop-types';

import classnames from 'classnames';
import { LazyHydrate, loadable } from 'nordic/lazy';

import { LAYOUTS } from '../../../../constants';
import { withComponentHandler } from '../../../../hocs/with-component-handler';
import { ResultPropTypes } from '../../constants/result.prop-types';

/* istanbul ignore next */
const ItemToPolyCard = loadable(
  async () =>
    import(
      /* webpackChunkName: "chunk-item-to-polycard" */
      '../../../polycard/componentes/item-to-polycard'
    ),
  {
    resolveComponent: (components) => components.ItemToPolyCard,
  },
);

/* istanbul ignore next */
const MapLayoutItem = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-layout-item-map" */
    '../../../../components/layout/renderer/layout-item/map'
  ),
);

/**
 * @typedef {Object} ItemProps
 * @property {string} deviceType
 * @property {string} index
 * @property {boolean} isPolycard
 * @property {boolean} isShops
 * @property {Object} item
 * @property {string} layout
 * @property {string} namespace
 * @property {Object} options
 * @property {boolean} options.lowEnd
 * @property {boolean} options.verboseLabels
 * @property {boolean} options.cardSupportsLinks
 * @property {string} vertical
 * @property {string} visualId
 */

/**
 * Item component renders different layouts based on the props provided.
 *
 * @param {ItemProps} props
 * @returns {JSX.Element} The rendered component.
 */
export const ItemComponent = (props) => {
  const { isPolycard, layout, item, namespace, index, isShops } = props;

  const listRef = useRef(null);

  return (
    <li
      key={`${item.id}-${index}`}
      ref={listRef}
      className={classnames(
        `${namespace}__item`,
        { 'shops__layout-item': isShops },
        { [`${namespace}__stack`]: !isPolycard },
      )}
    >
      {layout === LAYOUTS.TYPE_MAP && (
        <LazyHydrate whenVisible>
          <MapLayoutItem {...props} />
        </LazyHydrate>
      )}

      {isPolycard && (
        <LazyHydrate whenVisible>
          <ItemToPolyCard {...props} listRef={listRef} />
        </LazyHydrate>
      )}
    </li>
  );
};

ItemComponent.propTypes = {
  deviceType: string,
  index: string,
  isPolycard: bool,
  isShops: bool,
  item: shape(ResultPropTypes).isRequired,
  layout: string,
  namespace: string,
  options: shape({ lowEnd: bool, verboseLabels: bool, cardSupportsLinks: bool }),
  vertical: string,
  visualId: string,
};

export const Item = withComponentHandler(ItemComponent, {
  componentName: 'Item',
});
